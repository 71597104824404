<template>
  <v-autocomplete
    v-model="selectedSpeaker"
    :items="availableSpeakers"
    :loading="isSpeakersLoading"
    :search-input.sync="speakerApiSearch"
    v-on:input="onSpeakerSelected"
    :label="label ?? $t('Speaker')"
    no-filter
    hide-no-data
    outlined
    clearable
    :required="required"
    :disabled="disabled"
    :error-messages="errorMessages"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
      >
        {{ getProfileItemBasics(data.item.profile, 'firstName') }}
        {{ getProfileItemBasics(data.item.profile, 'lastName') }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ getProfileItemBasics(data.item.profile, 'firstName') }}
          {{ getProfileItemBasics(data.item.profile, 'lastName') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <template v-if="getProfileItemBasics(data.item.profile, 'company')">
            {{ getProfileItemBasics(data.item.profile, 'company') }}
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
  import ProfileMixin from '@/mixins/ProfileMixin';
  import SpeakerMixin from '@/mixins/SpeakerMixin';
  import { mapActions } from 'vuex';

  export default {
    name: 'SpeakerSearch',
    mixins: [ProfileMixin, SpeakerMixin],
    props: {
      value: {
        type: String,
        required: false,
        default: () => null
      },
      onSelected: {
        type: Function,
        required: true,
      },
      label: {
        type: String,
        required: false,
        default: () => null
      },
      required: {
        type: Boolean,
        required: false,
        default: () => false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: () => false
      },
      activeTenantFilter: {
        type: Boolean,
        required: false,
        default: () => false
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
    },

    data() {
      return {
        selectedSpeaker: null,
        speakerApiSearch: null,
        isSpeakersLoading: false,
        controller: new AbortController(),
        speakerSearchResults: []
      };
    },
    computed: {
      availableSpeakers() {
        return this.speakerSearchResults;
      },
    },
    methods: {
      ...mapActions('speaker', {
        getSpeakers: 'fetchAll'
      }),
      onSpeakerSelected(speaker) {
        this.onSelected(speaker);
      }

    },
    watch: {
      speakerApiSearch(val) {
        if (val.length < 3) {
          // min chars for search is 3 chars
          return;
        }
        this.isSpeakersLoading = true;
        this.controller.abort();
        this.controller = new AbortController();
        const signal = this.controller.signal;
        const params = {
          page: 1,
          itemsPerPage: 30,
          search: val,
          signal: signal
        };
        if (this.activeTenantFilter) {
          params['tenantUsers.tenant'] = this.userData.activeTenant['@id'];
        }
        this.getSpeakers(params)
          .then((result) => {
            this.speakerSearchResults = result ?? [];
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isSpeakersLoading = false;
          });
      }
    },
    mounted() {
      if (this.value) {
        const speakerResult = this.speakerById(this.value);
        if (speakerResult) {
          if (false === this.speakerSearchResults.includes(speakerResult)) {
            this.speakerSearchResults.push(speakerResult);
          }
          this.selectedSpeaker = speakerResult;
        }
      }
    }
  };
</script>
